import React, { useState } from "react"
import { useEffect } from "react"

import useHasMounted from "../../utils/useHasMounted"
import dataLayerEngagement from "../DataLayerEngagement"
import WebpImage from "../WebpImage"

import "./style.scss"

const VideoModule = ({ type, image, video, alt_text }) => {
  const [playVideo, setPlayVideo] = useState(false)
  const [videoSource, setVideoSource] = useState(video)
  const isMounted = useHasMounted()

  useEffect(() => {
    if(playVideo === true ){
      setVideoSource(video+'?autoplay=1&rel=0')
    }
  },[playVideo])

  const handlePlay = () => {
    setPlayVideo(true)
    dataLayerEngagement("home_page_engagement", "3","See Us In Action", `Play Video`)
  }

  return (
    <div className="video-module" data-type={type}>
      <div className="video-holder">
        <div className="grid-section">
          <div className="video-container">
            {playVideo && <iframe
              src={videoSource}
              title="Hubble Video"
              frameborder="0"
              className={`videoPlayer ${playVideo ? "visible" : "hidden"}`}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>}
            <div className={`copy-container  ${playVideo ? "hidden" : "visible"}`}>
              <div className="copy-holder">
                <span onClick={handlePlay}>
                  <WebpImage
                    fileName="Pages/Homepage/Play_Button.svg"
                    className="playButton"
                    alt=""
                  />
                </span>
                { type === 'contacts' &&
                  <>
                    <h3 className="text h3 white">Personalized Contact Lens Subscription Service</h3>
                    <p className="text h7 white neue">
                      Take an inside look at how our subscription service works.
                    </p>
                  </>
                }
                { type !== 'contacts' &&
                  <>
                    <h3 className="text h3 white">See Us In Action</h3>
                    <p className="text h7 white neue">
                      Take a look at how we make Hubble the most hassle-free contact lens and glasses experience in the world.
                    </p>
                  </>
                }
              </div>
            </div>
            {image && isMounted || image  ?
            <WebpImage objectFit="cover"
              wrapperClassName={`overlay-image ${playVideo ? "hidden" : "visible"}`}
              className={`overlay-image desktop ${playVideo ? "hidden" : "visible"}`}
              fileName={image}
              alt={alt_text} />
            :
            <>
            <div className={`overlay-image desktop ${playVideo ? "hidden" : "visible"}`}>
              <WebpImage
                style={{height: '100%'}}
                fileName="Pages/Homepage/Hubble-Contacts-Homepage-Video-Still.webp"
                alt={alt_text}
              />
            </div>
            <div className={`overlay-image mobile ${playVideo ? "hidden" : "visible"}`}>
              <WebpImage
                style={{height: '100%'}}
                fileName="Pages/Homepage/Hubble-Contacts-Homepage-Video-Still-Mobile.webp"
                alt={alt_text}
              />
            </div>
            </>}
          </div>
        </div>
      </div>
      <div className="grid-section">
        <div className="mobile-copy-holder">
        { type === 'contacts' &&
          <>
            <h3 className="text h3 white">Personalized Contact Lens Subscription Service</h3>
            <p className="text h7 white neue">
              Take an inside look at how our subscription service works.
            </p>
          </>
        }
        { type !== 'contacts' &&
          <>
            <h3 className="text h3 white">See Us In Action</h3>
            <p className="text h7 white neue">
              Take a look at how we make Hubble the most hassle-free contact lens and glasses experience in the world.
            </p>
          </>
        }
        </div>
      </div>
    </div>
  )
}

export default VideoModule
